export function createFetcher({ baseURL, headers }) {
    const fetcher = { get: () => {}, post: () => {}, delete: () => {} };

    fetcher.get = async function get(url, body) {
        try {
            const abortId = url.split("?")[0];
            if (fetcher[abortId]) {
                console.warn("A B O R T E D \nhttps: " + baseURL.slice(6) + url.split("?")[0] + "\n?" + url.split("?")[1]);
                fetcher[abortId].abort();
            }
            fetcher[abortId] = new AbortController();

            let _url = !url || url.startsWith("/") ? `${baseURL}${url}` : `${baseURL}/${url}`;

            let props = {
                "Content-Type": "application/json",
                signal: fetcher[abortId].signal,
                method: "GET",
                // mode: "no-cors",
                headers,
            };
            if (body) props.body = JSON.stringify(body);

            return new Promise(async (resolve, reject) => {
                const res = await fetch(_url, props);
                fetcher[abortId] = null;
                if (res.ok) {
                    resolve(res.json());
                } else {
                    let { bodyUsed, headers, redirected, status, statusText, type, url } = res;
                    reject({
                        bodyUsed,
                        // headers,
                        ...props,
                        redirected,
                        status,
                        statusText,
                        type,
                        url: _url,
                        statusMessage: statusCodeByMessage[status] || "Unknown Error",
                    });
                }
            });
        } catch (err) {
            console.error({ err });
            if (err.name === "AbortError") return;
            throw err;
        }
    };

    fetcher.post = async function get(url, body) {
        try {
            const abortId = url.split("?")[0];
            if (fetcher[abortId]) {
                console.warn("A B O R T E D \nhttps: " + baseURL.slice(6) + url.split("?")[0] + "\n?" + url.split("?")[1]);
                fetcher[abortId].abort();
            }
            fetcher[abortId] = new AbortController();

            let _url = !url || url.startsWith("/") ? `${baseURL}${url}` : `${baseURL}/${url}`;

            let props = {
                "Content-Type": "application/json",
                signal: fetcher[abortId].signal,
                method: "POST",
                // mode: "no-cors",
                headers,
            };
            if (body) props.body = JSON.stringify(body);

            return new Promise(async (resolve, reject) => {
                try {
                    const res = await fetch(_url, props);
                    fetcher[abortId] = null;
                    if (res.ok) {
                        resolve(res.json());
                    } else {
                        alert(JSON.stringify(res));

                        let { bodyUsed, headers, redirected, status, statusText, type, url } = res;
                        reject({
                            bodyUsed,
                            ...props,
                            redirected,
                            status,
                            statusText,
                            type,
                            url: _url,
                            statusMessage: statusCodeByMessage[status] || "Unknown Error",
                        });
                    }
                } catch (err) {
                    alert(JSON.stringify(err));

                    err.statusText = "ERR_CONNECTION_TIMED_OUT";
                    if (err.name === "AbortError") return;
                    throw err;
                }
            });
        } catch (err) {
            err.statusText = "ERR_CONNECTION_TIMED_OUT";
            alert(JSON.stringify(err));
            console.error({ err });
            if (err.name === "AbortError") return;
            throw err;
        }
    };

    return fetcher;
}

// const fetcher = createFetcher({
//     baseURL: "https://cosmic-boaconstrictor.morabaa.cloud/api/v1",
// });
// fetcher.get("errors/default?").then((res) => {
//     console.log({ res });
// });
// fetcher.get("errors/default?").then((res) => {
//     console.log({ res });
// });
const statusCodeByMessage = {
    0: "There Is No Response From Server Body Is Empty Connection May Be Very Slow",

    100: " Continue ",
    101: " Switching protocols ",
    102: " Processing ",
    103: " Early Hints ",

    //2xx Succesful
    200: " OK ",
    201: " Created ",
    202: " Accepted ",
    203: " Non-Authoritative Information ",
    204: " No Content ",
    205: " Reset Content ",
    206: " Partial Content ",
    207: " Multi-Status ",
    208: " Already Reported ",
    226: " IM Used ",

    //3xx Redirection
    300: " Multiple Choices ",
    301: " Moved Permanently ",
    302: " Found (Previously 'Moved Temporarily') ",
    303: " See Other ",
    304: " Not Modified ",
    305: " Use Proxy ",
    306: " Switch Proxy ",
    307: " Temporary Redirect ",
    308: " Permanent Redirect ",

    //4xx Client Error
    400: " Bad Request ",
    401: " Unauthorized ",
    402: " Payment Required ",
    403: " Forbidden ",
    404: " Not Found ",
    405: " Method Not Allowed ",
    406: " Not Acceptable ",
    407: " Proxy Authentication Required ",
    408: " Request Timeout ",
    409: " Conflict ",
    410: " Gone ",
    411: " Length Required ",
    412: " Precondition Failed ",
    413: " Payload Too Large ",
    414: " URI Too Long ",
    415: " Unsupported Media Type ",
    416: " Range Not Satisfiable ",
    417: " Expectation Failed ",
    418: " I'm a Teapot ",
    421: " Misdirected Request ",
    422: " Unprocessable Entity ",
    423: " Locked ",
    424: " Failed Dependency ",
    425: " Too Early ",
    426: " Upgrade Required ",
    428: " Precondition Required ",
    429: " Too Many Requests ",
    431: " Request Header Fields Too Large ",
    451: " Unavailable For Legal Reasons ",

    //5xx Server Error
    500: " Internal Server Error ",
    501: " Not Implemented ",
    502: " Bad Gateway ",
    503: " Service Unavailable ",
    504: " Gateway Timeout ",
    505: " HTTP Version Not Supported ",
    506: " Variant Also Negotiates ",
    507: " Insufficient Storage ",
    508: " Loop Detected ",
    510: " Not Extended ",
    511: " Network Authentication Required ",
};
