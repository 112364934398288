const Root = {
    theme: localStorage.getItem("theme") || "light",
    toggleTheme: (theme) => {
        if (typeof theme !== "string") theme = Root.theme === "dark" ? "light" : "dark";
        Root.setTheme(theme);
    },
    setTheme: (theme) => {
        Root.theme = theme;
        localStorage.setItem("theme", theme);
        document.documentElement.className = theme;
    },
    initTheme: () => {
        document.documentElement.className = Root.theme;
    },
};
export default Root;
