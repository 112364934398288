import React from "react";
const getClass = (prop) => `owl-toggleed-bg ${prop.value ? "owl-toggleed" : ""}`;

const Boolean = ({ prop }) =>
    React.useMemo(() => {
        return (
            <div className={`owl-toggle-button ${prop.className}`} key={prop.key}>
                <p
                    onClick={({ currentTarget }) => {
                        prop.value = !prop.value;
                        currentTarget.classList.toggle("owl-toggleed");

                        if (prop.storageKey) {
                            localStorage.setItem(prop.storageKey, prop.value);
                            console.log(prop.storageKey, prop.value);
                        }
                        if (prop.showInClearBar)
                            prop.clear = () => {
                                prop.value = !prop.value;
                                currentTarget.classList.toggle("owl-toggleed");
                            };
                        prop.onChange();
                    }}
                    className={getClass(prop)}>
                    <span />
                </p>
                <p className="font-bold owl-text-xs">{prop.title}</p>
            </div>
        );
    }, []);

export default Boolean;

// import React from "react";
// import Toggle from "../Kit/Toggle";

// const Boolean = ({ prop }) =>
//     React.useMemo(
//         () => (
//             <Toggle
//                 callBack={prop.showInClearBar}
//                 className={prop.className}
//                 onChange={prop.onChange}
//                 storageKey={prop.storageKey}
//                 title={prop.title}
//                 value={prop.value}
//             />
//         ),
//         []
//     );

// export default Boolean;
