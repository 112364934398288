import { createFetcher } from "./Fetcher.js";
import UrlsGenerater from "./UrlGenertor.js";
import { GetItems } from "../Utils/MockGenerator.js";

export default class CreateApiProviderClass {
    constructor({ baseURL, api, url, limit = 25, useCash, onSearch, onFetch, onResult, onError, scrollerId }) {
        this.canFetch = true;
        this.offset = 0;
        this.api = api ?? createFetcher({ baseURL });
        this.url = url;
        this.query = "";
        this.limit = limit;
        this.useCash = useCash;

        this.onSearch = onSearch;
        this.onFetch = onFetch;
        this.onResult = onResult;
        this.onError = onError;

        this.setProviderState = () => {};
        this.providerState = "none";

        this.queryParams = {};
        this.setQueryParams = (child) => {
            console.log(child);
            if (child.value) this.queryParams[child.key] = { value: child.value, title: child.title || "_" };
            else delete this.queryParams[child.key];
            console.log(this.queryParams);
            this.search();
        };

        if (!this.api) {
            this.limit = 3_00;
            this.fetch = () => localFetching(this);
            this.search = () => localFetching(this);
        } else {
            this.fetch = setupFetchEvent(this);
            this.search = setupSearchEvent(this);
        }
        addScrollEvent(this, scrollerId);
        initialiser(this);
    }
}

const setupFetchEvent =
    (apiProvider) =>
    async (method = "get") => {
        apiProvider.canFetch = false;
        let query = apiProvider.query + `&offset=${apiProvider.offset}`;
        apiProvider.setProviderState("itemsLoading");

        apiProvider.onFetch && apiProvider.onFetch(apiProvider);
        try {
            console.log({ query });
            const result = await apiProvider.api[method](query);
            onResult(apiProvider, result);
        } catch (error) {
            onError(apiProvider, error);
        }
    };

function setupSearchEvent(apiProvider) {
    return async (method = "get") => {
        apiProvider.setProviderState("searching");
        apiProvider.canFetch = false;
        apiProvider.offset = 0;
        apiProvider.limit = apiProvider.queryParams.limit?.value || 25;

        apiProvider.query = UrlsGenerater(apiProvider.queryParams, apiProvider.url);
        apiProvider.onSearch && apiProvider.onSearch(apiProvider);

        if (apiProvider.useCash) {
            let cashItems = JSON.parse(localStorage.getItem(apiProvider.query)) || [];
            if (cashItems.length > 0) {
                setTimeout(() => {
                    apiProvider.canFetch = true;
                }, 100);
                apiProvider.setProviderState("none");
                apiProvider.setItems(cashItems, true);
                return;
            }
        }
        try {
            const result = await apiProvider.api[method](apiProvider.query);
            // console.log("fromApiProvider_result", result);
            onResult(apiProvider, result);
        } catch (error) {
            console.log({ error });
            onError(apiProvider, error);
        }
    };
}

const onResult = async (apiProvider, data) => {
    if (apiProvider.onResult) {
        let modfied = await apiProvider.onResult(data, apiProvider.offset === 0);
        if (modfied) data = modfied;
    }
    let items = [];
    let _data = {};
    if (getType(data) === "Object") {
        Object.entries(data).forEach(([key, value]) => {
            if (Array.isArray(value)) items = value;
            else _data[key] = value;
        });
    } else items = data || [];

    if (apiProvider.useCash)
        if (apiProvider.offset === 0) {
            let allCashQueries = JSON.parse(localStorage.getItem("all-cash-queries")) || [];
            if (!allCashQueries.includes(apiProvider.query)) {
                allCashQueries.push(apiProvider.query);
                localStorage.setItem("all-cash-queries", JSON.stringify(allCashQueries));
            }
            localStorage.setItem(apiProvider.query, JSON.stringify(items));
        } else {
            let oldItems = JSON.parse(localStorage.getItem(apiProvider.query)) || [];
            localStorage.setItem(apiProvider.query, JSON.stringify([...oldItems, ...items]));
        }
    apiProvider.setItems((_items) => {
        return apiProvider.offset === 0 ? items : [..._items, ...items];
    });
    apiProvider.offset += items.length;
    apiProvider.canFetch = items.length >= apiProvider.limit;
    apiProvider.setProviderState(items.length > 0 ? "none" : "noData");
};

const initialiser = (apiProvider) => {
    setTimeout(() => {
        apiProvider.search();
    }, 100);
};

const getType = (obj) => Object.prototype.toString.call(obj).slice(8, -1);

function onError(apiProvider, error) {
    apiProvider.onError && apiProvider.onError(error);
    // if (error.response && error.response.status) {
    //     let status = error.response.status;
    //     let main = {
    //         code: error.code,
    //         name: error.name,
    //         message: error.message,
    //         status: { [status]: error.response.data.title },
    //         config_: { baseURL: error.config.baseURL },
    //     };
    //     let header = error.config.headers;
    //     error = { ...main, header, ...error };
    //     delete error.config;
    //     delete error.response;
    // } else if (error.stack) {
    //     error = { message: error.message, stack: error.stack };
    // }
    // apiProvider.onError && apiProvider.onError("error", error);
}

const addScrollEvent = (apiProvider, scrollerId) => {
    if (scrollerId) {
        setTimeout(() => {
            let scroller = document.getElementById(scrollerId);
            scroller &&
                scroller.addEventListener("scroll", ({ target }) => {
                    if (apiProvider.canFetch && target.scrollHeight - target.scrollTop < target.clientHeight + 100) {
                        apiProvider.canFetch = false;
                        apiProvider.fetch();
                    }
                });
        }, 100);
    }
};

// LOCAL FETCHING
function localFetching(apiProvider) {
    apiProvider.onFetch && apiProvider.onFetch();

    apiProvider.canFetch = false;
    try {
        let data = GetItems(apiProvider);
        LocalSetOnResult(apiProvider, data);
    } catch (error) {
        onError(apiProvider, error);
    }
}

const LocalSetOnResult = async (apiProvider, items) => {
    if (apiProvider.onResult) {
        let modfied = await apiProvider.onResult(items);
        if (modfied) items = modfied;
    }
    apiProvider.offset += items.length;
    apiProvider.canFetch = items.length == apiProvider.limit;
};
