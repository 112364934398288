// import axios from "axios";

// const getAxiosInstance = ({ baseURL, token, appPackage, other }) => {
//     const headers = {
//         "Content-Type": "application/json",
//     };
//     if (appPackage) headers["App-Package"] = appPackage;
//     if (other) headers[other.key] = other.value;

//     let instance = axios.create({
//         baseURL,
//         headers,
//     });
//     instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
//     return instance;
// };
// export default getAxiosInstance;

export const hasValue = (value) => [undefined, null, -1, "", "-1"].includes(value) === false;
