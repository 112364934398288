const Language = {
    PrintAndSave: "بيع وطباعة",
    ReturnSaleAndPrint: "ارجاع وطباعة",
    Save: "حفظ",
    Send: "بيع",
    Sended: "تم الارسال",
    AddDiscount: "إضافة خصم",
    ReturnSale: "إرجاع بيع",
    AddNote: "اضافة ملاحظة",
    CencelTheList: "إلغاء القائمة",
    AllTheStores: "كل المتاجر",
    All: "الكل",
    SearchingFor: "...البحث عن",
    Settings: "الإعدادات",
    CencelTheDiscont: "إلغاء الخصم",
    CencelTheNote: "إلغاء الملاحظة",
    TheTotal: "المجموع",
    Settings: "الإعدادات",
    AlMorabaa: "شركة المربع للحلول البرمجية",
    SaleBill: "قائمة بيع/نقدي",
    ReturnBill: "قائمة ارجاع/نقدي",
    TheName: "الإسم",
    BillNumber: "رقم الفاتورة",
    TheDate: "التاريخ",
    TheTime: "الوقت",
    TheUser: "المستخدم",
    Cancel: "إلغاء",
    Note: "الملاحظة",
    Delete: "حذف ",
    SearchingFor: "البحث عن ...",

    ReceiptSettings: "إعدادات الفاتورة",
    PrintBillOnSale: "طباعة الفاتورة عند البيع",
    PrintBillOnReturnSale: "طباعة الفاتورة عند الارجاع",

    ShowBillList: "قوائم متعددة",
    Show: "اظهار",
    Hide: "اخفاء",

    TheCurrency: "عملة البيع",
    1: "دينار",
    2: "دولار",
    UseCash: "تخزين المواد",

    NotificationsOnSaleInNagative: "الإشعار عند البيع بالسالب",
    PoupKeyborad: "كيبورد على الشاشة",

    Active: "مفعل",
    UnActive: "غير مفعل ",

    UserName: "اسم المستخدم",
    Password: "كلمة المرور",
    Login: "تسجيل الدخول",

    ErrorHappend: "حدث خطأ ما!",
    UserOrPasswordError: "خطأ في اسم المستخدم او كلمة المرور",
    NetError: "تأكد من اتصالك بالخادم!",

    Logout: "تسجيل الخروج",
    Yes: "نعم",
    LogoutMessage: " هل تود تسجيل الخروج ؟",
    LogoutMessageWorrning: "سيتم حذف جميع البيانات المحفوظة ",

    Remaining: "الباقي",
    TheDiscount: "الخصم",
    TheTotalAfterDiscount: "المجموع بعد الخصم ",
    Paid: "الواصل ",
    AreYouSure: "هل انت متأكد ؟",
    Worrning: "تحذير  ! ",
    AllBillsWellBeDeleted: "سيتم حذف جميع الفواتير",
    ClearCash: "إعادة تحميل المواد",
    ClearCashMessage: "سيتم حذف جميع المواد المحملة ! ",
    ShowNotificationOnSaleInNegative: "الإشعار عند البيع بالسالب",
    CannotSaleInNegative: "لا يمكن البيع بالسالب",
    SaleInNegativeWarnning: "تحذير ! البيع بالسالب",

    RongDiscount: "خطأ في الخصم",
    ItemQuantityLessThenQuantityInStore: "الكمية المطلوبة اكبر من الكمية المتوفرة",

    CantEnterNegative: "لا يمكنك ادخال قيمة سالبة",
    Error: "خطأ",
    InternetProplem: "مشكلة في الشبكة",
    NoInternet: "لا يوجد اتصال بالشبكة او السرفر !",
    NotFound: "غير موجودة",
    ItemNotFound: "لاتوجد مادة تحمل هذا الباركود",

    QunatityUnAvailable: "الكمية غير متوفرة",
    FetchAll: "تحميل كل المواد",
    PrintLastBill: "طباعة اخر فاتورة",
    Sale: "بيع",
    Return: "ارجاع",
    OnSale: "البيع",
    OnReturnSale: "الارجاع",
    OnSaleAndReturn: "البيع والارجاع",
    UpdateBannerAndLogo: "تحديث البنر والشعار",
    Updated: "تم التحديث",

    name: "الاسم",
    address: "العنوان",
    phone: "الهاتف",

    Required: "هذ الحقل مطلوب",

    AddAccountTitle: "اضافة حساب جديد ",
    MainTitle: "تطبيق الجرد",
    ServerError: "خطأ في الاتصال بالسرفر",
    NoServerConnection: "لا يوجد اتصال بالسرفر",
};

export default Language;
