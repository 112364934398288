import React from "react";
import Language from "../Utils/Language";

const PopupConfirmer = ({ close, confirm, warnningText = Language.Logout }) => {
    return (
        <div onClick={close} className="fixed inset-0 col-center z-50" style={{ backgroundColor: "#0009" }}>
            <div
                style={{ width: "100%", maxWidth: 250 }}
                className="m-auto font-bold bg-prim rounded-lg px-lg py-3xl justify-around flex flex-col items-center">
                <p style={{ fontSize: 16 }} className="">
                    {warnningText}!
                </p>
                <br />
                <p className="row justify-between gap-xl text-md">
                    <span onClick={confirm} className="shadow-lg bg-dolpahin cursor-pointer py-sm px-md rounded-md">
                        {Language.Yes}
                    </span>
                    <span onClick={close} className="shadow-lg bg-dolpahin cursor-pointer py-sm px-md rounded-md">
                        {Language.Cancel}
                    </span>
                </p>
            </div>
        </div>
    );
};
export default PopupConfirmer;
