import React from "react";
import { memo } from "react";
import PageStateKit from "./PageStateKit";

const getGlobalProvider = (id) => {
    if (!id) return global;
    if (!global[id]) global[id] = {};
    return global[id];
};

const PageStateProvider = ({ id, provider }) => {
    const _provider = React.useMemo(() => provider || getGlobalProvider(id), []);

    [_provider.providerState, _provider.setProviderState] = React.useState(_provider.providerState);

    return React.useMemo(() => {
        console.debug("PageStateProvider Render");
        const PageState = PageStateKit[_provider.providerState];
        return <PageState provider={_provider} />;
    }, [_provider.providerState]);
};

export default memo(PageStateProvider);
