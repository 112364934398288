import React from "react";
import Info from "../NodeBuilder/Info";

const submit = async (item, provider) => {
    let updatedItem = {
        itemId: parseInt(item.id.split("-")[0]),
        userId: JSON.parse(localStorage.getItem("logged-user")).id,
        fillingId: item.packageId,
        storeId: item.storeId,
        quantity: item.quantity,
    };

    provider.api
        .post("inventory/stocks", [updatedItem])
        .then((result) => {
            let newItem = result[0];
            let title = "تم تعديل عدد المادة";
            if (newItem.packages) {
                title = "تم تعديل اعداد المادة";
                item.packages = newItem.packages.map((pack) => {
                    if (item.packageId === pack.id) {
                        pack.is = "selected";
                        item.quantity = pack.quantity;
                    } else pack.is = "";
                    return pack;
                });
            } else {
                item.quantity = newItem.quantity;
            }
            const inputQuantity = document.querySelector(`[id='${item.id}']`);
            inputQuantity.value = item.quantity;

            Info({ title, type: "success" });
            if (item.pastQuantity > -1)
                item.undo = async () => {
                    item.quantity = item.pastQuantity;
                    item.pastQuantity = null;
                    await submit(item, provider);
                    item.undo = null;
                };

            newItem = { ...item };
            provider.setItem(newItem);
        })
        .catch((err) => {
            Info({ title: "Error", content: err.message || "حصل خطاء", type: "error" });
        });
};
const ItemBuilder = ({ item, provider }) => {
    const onPackChange = (e, pack) => {
        const parent = e.currentTarget.parentElement;
        parent.querySelector(`[is="selected"]`).setAttribute("is", "");
        e.currentTarget.setAttribute("is", "selected");
        item.quantity = pack.quantity;
        item.packageId = pack.id;
        const inputQuantity = document.querySelector(`[id='${item.id}']`);
        inputQuantity.value = pack.quantity;
        inputQuantity.readOnly = false;
        inputQuantity.focus();
    };
    return (
        <div className="item-card">
            <div className="row-center justify-between">
                <p>{item.name}</p>
                <p className="text-shark border-gray self-start px-2xl rounded-lg">{item.store}</p>
            </div>
            <div className="row border-top  gap-lg justify-between">
                <div className="gap-xs col">
                    {item.undo ? (
                        <p onClick={item.undo} className="undo text-center">
                            {"تراجع"}
                        </p>
                    ) : (
                        <span className="text-xs text-dolphin text-center">{" العدد "}</span>
                    )}
                    <input
                        id={item.id}
                        type="number"
                        className="quantity-input"
                        readOnly={true}
                        onClick={(e) => {
                            e.currentTarget.readOnly = false;
                        }}
                        defaultValue={item.quantity}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                document.querySelector(`[id='${item.id}']`).blur();
                                let value = parseInt(e.currentTarget.value);
                                if (value === "" || value === item.quantity) {
                                    e.currentTarget.value = item.quantity;
                                } else {
                                    item.pastQuantity = item.quantity;
                                    item.quantity = value;
                                    submit(item, provider);
                                }
                            }
                        }}
                        onBlur={(e) => {
                            e.currentTarget.readOnly = true;
                        }}
                        onFocus={(e) => {
                            e.currentTarget.select();
                        }}
                    />
                </div>
                {item.packages && (
                    <div className="packages">
                        {item.packages.map((pack) => (
                            <p
                                className="package"
                                onClick={(e) => {
                                    onPackChange(e, pack);
                                }}
                                key={pack.id}
                                is={pack.is}>
                                <span className="text-sm">{pack.quantity}</span>
                                <br />
                                <span>{pack.name}</span>
                            </p>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default React.memo(ItemBuilder);
