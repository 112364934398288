import "./index.css";
import { createRoot } from "react-dom/client";
import Root from "./scripts/Root";
// import App from "./MenuProvider/App";
import GardView from "./Grad/GardView";
import LoginRoute from "./Grad/LoginRoute";
import { createFetcher } from "./scripts/Fetcher";
import { BrowserRouter as Router } from "react-router-dom";
import { Capacitor } from "@capacitor/core";
import GetHost from "./Grad/GetHost";

Root.initTheme();

// document.body.append(dev);
// dev.style.left = 300 + "px";
// dev.style.top = 300 + "px";

// window.addEventListener("mousemove", (e) => {
//     // console.log(e.currentTarget);
//     dev.style.left = e.clientX - 20 + "px";
//     dev.style.top = e.clientY - 20 + "px";
// });
let host = localStorage.getItem("host");
if (!host) {
    createRoot(document.getElementById("root")).render(<GetHost />);
} else {
    let loggedUser = localStorage.getItem("logged-user");
    global.platform = Capacitor.getPlatform();

    const OnlineStoreRoot = host === "test" || host === "Test" ? "https://smooth-dove.morabaa.cloud/api/v1" : `https://${host.split(":")[0]}:7778/api/v1`; //true ? "http://192.168.120.214:7777/api/v1" : "https://smooth-dove.morabaa.cloud/api/v1";

    const api = createFetcher({
        baseURL: OnlineStoreRoot,
        headers: {
            accept: "text/plain",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            token: localStorage.getItem("token"),
        },
    });

    createRoot(document.getElementById("root")).render(<Router>{loggedUser ? <GardView api={api} /> : <LoginRoute api={api} />}</Router>);
}
