import React from "react";
const String = ({ prop }) =>
    React.useMemo(
        () => (
            <div key={prop.key} className={prop.containerClass || "relative"}>
                <input
                    type="text"
                    tabIndex="-1"
                    id={prop.key}
                    name={prop.key}
                    placeholder={prop.title}
                    onChange={({ target }) => {
                        checkChange(target, prop);
                    }}
                    className={prop.className}
                />
                <span
                    className="clear-icon hide"
                    onClick={({ target }) => {
                        prop.value = "";
                        target.previousSibling.value = "";
                        target.classList.add("hide");
                        prop.onChange();
                    }}>
                    x
                </span>
            </div>
        ),
        []
    );

export default String;

const checkChange = (target, prop) => {
    let pastValue = target.value;
    setTimeout(() => {
        if (pastValue !== target.value) return;
        {
            prop.value = target.value;
            const clear = prop.showInClearBar
                ? () => {
                      prop.value = "";
                      target.value = "";
                      target.nextSibling.classList.add("hide");
                      prop.onChange();
                  }
                : null;
            prop.onChange(clear);
            if (target.value) target.nextSibling.classList.remove("hide");
            else target.nextSibling.classList.add("hide");
        }
    }, 700);
};
