import React from "react";
import JsonToView from "../Defaults/JsonToView";
import PageStateProvider from "../PageStateKit/PageStateProvider";
import { createFetcher } from "../scripts/Fetcher";
import CreateApiProviderClass from "../scripts/CreateApiProviderClass";
import FormContainer from "../Form/FormContainer";
import FormSecripts from "../Form/FormScripts";
import Root from "../scripts/Root";
import Info from "../NodeBuilder/Info";
import TestData from "../Test/Consts";
import Grid from "../Containers/Grid";
import ItemBuilder from "./ItemBuilder";
import "./grad.css";
import Language from "./Utils/Language";
import LogoutComponent from "./Components/LogoutComponent";
import CustomEvents from "../scripts/Events";

const listnToBarcode = (provider) => {
    global.barcode = "";
    global.focused = false;

    window.addEventListener("keydown", async (e) => {
        if (global.focused) return;
        if (e.key === "Enter") {
            if (global.barcode.length < 1) {
                global.barcode = "";
                return;
            }
            CustomEvents.setToInput("query", global.barcode);
            global.barcode = "";
        } else global.barcode += e.key;
    });
};

const onResult = (data) => {
    let inventories = JSON.parse(localStorage.getItem("inventory-list"))?.options || [];
    data.map((item) => {
        item.id = `${item.id}-${item.storeId}`;
        item.store = inventories.find((i) => i.id === item.storeId)?.title;
        if (item.packages) {
            item.packages = item.packages.map((pack) => {
                if (item.packageId === pack.id) {
                    pack.is = "selected";
                    item.quantity = pack.quantity;
                } else pack.is = "";
                return pack;
            });
        }
    });
    if (data.length === 1) {
        let inputQuantity = document.querySelector(`[id='${data[0].id}']`);
        inputQuantity.readOnly = false;
        inputQuantity.focus();
        inputQuantity.value = data[0].quantity;
        inputQuantity.select();
    }
    return data;
};
const GardView = ({ api }) => {
    // localStorage.removeItem("logged-user");
    const apiProvider = React.useMemo(() => {
        init(api);
        const _apiProvider = new CreateApiProviderClass({ api, url: "pos/items", onResult, scrollerId: "rapper", useCash: false });
        listnToBarcode(_apiProvider);
        return _apiProvider;
    }, []);

    apiProvider.queryParams = React.useMemo(() => FormSecripts.extractValues(TestData.formSections), []);

    return (
        <div onFocus={() => (global.focused = true)} onBlur={() => (global.focused = false)} id="rapper" className="scroller font-bold">
            <FormContainer onChange={apiProvider.setQueryParams} children={TestData.formSections} />
            <PageStateProvider provider={apiProvider} />
            {/* <p className="button shadow-lg text-shark" onClick={Root.toggleTheme}>
                Theme
            </p> */}
            {/* <JsonToView item={apiProvider.queryParams} /> */}
            <Grid ItemBuilder={ItemBuilder} provider={apiProvider} />
            <LogoutComponent />
        </div>
    );
};

export default GardView;

const init = (API) => {
    const getGropus = async () => {
        let groupChildren = [{ key: "groupId", title: "الكل", id: -1 }];
        const data = await API.get("pos/groups?limit=100");
        if (data.length < 1) {
            localStorage.removeItem("groups-list");
            return;
        }
        data.forEach((element) => {
            groupChildren.push({ title: element.name, id: element.id });
        });
        const toLocalStorage = { key: "groupId", type: "options", value: -1, options: groupChildren, storageKey: "selected-group" };
        localStorage.setItem("groups-list", JSON.stringify(toLocalStorage));
    };

    const getStorse = async () => {
        let storesChildren = [{ key: "stoteId", title: "كل المخازن", id: -1 }];
        const data = await API.get("pos/stores?limit=100&userId=" + JSON.parse(localStorage.getItem("logged-user")).id);
        if (data.length < 1) {
            localStorage.removeItem("inventory-list");
            return;
        }
        data.forEach((element) => {
            storesChildren.push({ title: element.name, id: element.id });
        });
        const toLocalStorage = { key: "storeId", type: "selector", value: -1, options: storesChildren, storageKey: "selected-store" };
        if (!localStorage.getItem("inventory-list"))
            setTimeout(() => {
                window.location.reload();
            }, 300);
        localStorage.setItem("inventory-list", JSON.stringify(toLocalStorage));
    };
    Promise.all([getGropus(), getStorse()]).catch((err) => {});
};
