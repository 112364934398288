import React from "react";
import InputText from "../Components/InputText";
import Info from "../NodeBuilder/Info";
import MorabaaIcon from "./Components/MorabaaIcon";
import Language from "./Utils/Language";

const GetHost = () => {
    const [host, setHost] = React.useState(localStorage.getItem("old-host") || "");
    const loginBtnRef = React.useRef(null);
    const onClickLogin = async (e) => {
        if (host === "test") {
            localStorage.setItem("host", host);
            window.location.reload();
        }
        if (host === "") return;
        if (host.split(".").length < 4) {
            Info({ title: "الرجاء ادخال ايبي صحيح", type: "error" });
            return;
        }
        localStorage.setItem("host", host);
        window.location.reload();
    };

    return (
        <div className="h-screen col overflow-auto p-3" style={{ height: "100vh" }}>
            <div className="col p-xl gap-xl justify-center mx-auto font-bold bg-white shadow-lg rounded-lg m-auto">
                <MorabaaIcon />
                <span className="text-dolphin text-sm">{"مثال :  192.168.1.13"}</span>
                <InputText required value={host} placeholder={"ادخل ايبي الحاسبة الرئيسية"} setValue={setHost} />
                <p type="subimt" ref={loginBtnRef} className="button m-auto bg-primary text-wolf text-sm" onClick={onClickLogin}>
                    <span>{Language.Save}</span>
                    <span id="loader-target" />
                </p>
                <input id="host" type="submit" style={{ display: "none" }} onClick={() => loginBtnRef.current.click()} />
            </div>
        </div>
    );
};

export default GetHost;
