const InputText = ({ value, placeholder, setValue, type = "text", className }) => (
    <input
        type={type}
        name="placeholder"
        className={"owl-input " + className}
        value={value}
        placeholder={placeholder}
        onChange={({ target }) => setValue(target.value)}
    />
);
export default InputText;
