import React from "react";
const Options = ({ prop }) =>
    React.useMemo(() => {
        const onClick = ({ target, currentTarget }) => {
            let value = target.title;
            if (!value || value === prop.value) return;
            prop.value = value;
            currentTarget.querySelector(".selected")?.classList.remove("selected");
            target.classList.add("selected");
            if (prop.storageKey) localStorage.setItem(prop.storageKey, prop.value);
            prop.onChange();
        };

        return (
            <div
                id={`${prop.type}-${prop.key}`}
                onClick={onClick}
                className={`owl-options-container ${prop.className || ""}`}
                onWheel={onWheel}
                onMouseMove={onDrag}
                onMouseDown={(e) => {
                    firstX = e.clientX;
                }}
                onMouseUp={(e) => {
                    firstX = 0;
                    draged = false;
                }}>
                {prop.options.map((item) => (
                    <p title={item.id} className={`owl-button-options ${item.className || ""} ${prop.value == item.id ? "selected" : ""}`} key={item.id}>
                        {item.title}
                    </p>
                ))}
            </div>
        );
    }, []);

export default Options;

let wheeled = false;
const onWheel = (e) => {
    if (wheeled) return;
    wheeled = true;
    setTimeout(() => {
        wheeled = false;
    }, 200);

    console.debug(e.currentTarget.clientWidth);
    let left = (e.currentTarget.clientWidth * e.deltaY) / 110;
    e.currentTarget.scrollBy({
        top: 0,
        left,
        behavior: "smooth",
    });
};
let firstX = 0;
let draged = false;
const onDrag = (e) => {
    if (firstX == 0 || draged) return;
    draged = true;

    let left = (e.currentTarget.clientWidth * 100) / 110;
    if (e.clientX - firstX > 10) {
        e.currentTarget.scrollBy({
            top: 0,
            left: -left,
            behavior: "smooth",
        });
    } else if (e.clientX - firstX < -10) {
        e.currentTarget.scrollBy({
            top: 0,
            left,
            behavior: "smooth",
        });
    } else
        setTimeout(() => {
            draged = false;
        }, 50);

    // console.debug(e.currentTarget.clientWidth);
    // let left = (e.currentTarget.clientWidth * e.deltaY) / 110;
    // e.currentTarget.scrollBy({
    //     top: 0,
    //     left,
    //     behavior: "smooth",
    // });
};
