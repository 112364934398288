import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import InputText from "../Components/InputText";
import Language from "./Utils/Language";
import MorabaaIcon from "./Components/MorabaaIcon";
import uuid from "./Utils/Consts";

const LoginRoute = ({ api }) => {
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    let navigate = useNavigate();
    const loginBtnRef = React.useRef(null);

    React.useEffect(() => {
        if (!localStorage.getItem("token")) {
            localStorage.setItem("token", uuid());
            window.location.reload();
        }
    });

    const onClickLogin = async (e) => {
        if (userName === "" || password === "") return;
        if (loginBtnRef.current.classList["loading"]) return;
        loginBtnRef.current.classList.add("loading");
        await api
            .post("pos/login", { userName, password })
            .then((data) => {
                localStorage.setItem("logged-user", JSON.stringify(data));
                navigate("/");
                setTimeout(() => {
                    window.location.reload();
                }, 10);
            })
            .catch((e) => {
                loginBtnRef.current.classList.remove("loading");
                loginBtnRef.current.classList.add("error");
                console.log({ e });
                loginBtnRef.current.firstChild.innerText = Language[ErrorHandler[e.statusText]];
                setTimeout(() => {
                    loginBtnRef.current.classList.remove("error");
                    loginBtnRef.current.firstChild.innerText = Language.Login;
                }, 1500);
            });
    };

    return (
        <div className="h-screen col overflow-auto p-3" style={{ height: "100vh" }}>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    loginBtnRef.current.click();
                    return false;
                }}
                className="col p-xl gap-xl justify-center mx-auto font-bold bg-white shadow-lg rounded-lg m-auto">
                <p className="text-center text-active text-xl">{Language.MainTitle} </p>
                <p
                    className="button m-auto bg-primary text-dragon text-sm row gap-2xl"
                    onClick={() => {
                        localStorage.setItem("old-host", localStorage.getItem("host"));
                        localStorage.removeItem("host");
                        window.location.reload();
                    }}>
                    <span>{"تغيير"}</span>
                    <span>{localStorage.getItem("host")}</span>
                </p>
                <MorabaaIcon />
                <InputText required value={userName} placeholder={Language.UserName} setValue={setUserName} />
                <InputText required placeholder={Language.Password} value={password} setValue={setPassword} type="password" />
                <p type="subimt" ref={loginBtnRef} className="button m-auto bg-primary text-wolf text-sm" onClick={onClickLogin}>
                    <span>{Language.Login}</span>
                    <span id="loader-target" />
                </p>
                <input type="submit" style={{ display: "none" }} onClick={() => loginBtnRef.current.click()} />
            </form>
        </div>
    );
};

export default LoginRoute;

const ErrorHandler = {
    ERR_CONNECTION_TIMED_OUT: "NoServerConnection",
    ERR_NETWORK: "NetError",
    ECONNABORTED: "ServerError",
    "Bad Request": "UserOrPasswordError",
};
