import React from "react";
import PopupConfirmer from "./Logout";

const LogoutComponent = () => {
    const [logConfirm, setLogConfirm] = React.useState(false);
    return (
        <>
            <div onClick={() => setLogConfirm(true)} className="fixed rounded-full  left-0 bottom-0" style={{ margin: 10 }}>
                <svg height={25} fill="#e3353c" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 339.58">
                    <path
                        d="M330,382c1.72-7,2.36-14.55,5.36-21,9.4-20.09,25.81-30.38,48-30.45q83.75-.29,167.49,0c26.21.11,45.75,16,51.56,41.3a40,40,0,0,1,.53,13.81c-.8,6.2-6.73,10.69-13,11A14.32,14.32,0,0,1,576,386.58a28.5,28.5,0,0,1-.95-5.89c-.75-12.47-10.19-21.67-22.81-21.68q-85.24-.11-170.49,0A22.34,22.34,0,0,0,359,381.74q-.08,118.25,0,236.49A22.35,22.35,0,0,0,381.75,641c56.66.08,113.33-.1,170,.16,12,.05,23.38-10.44,23.42-22.33,0-8.23,4.32-13.35,11.27-15a13.92,13.92,0,0,1,15.29,6.69c3.16,5.67,1.93,11.74.74,17.54-4.9,23.72-25.41,40.2-47.1,41.15A17.57,17.57,0,0,0,552,670H382a32.26,32.26,0,0,0-3.85-.84c-20.22-2-34.76-12.3-43-30.71-2.82-6.32-3.51-13.6-5.17-20.45Z"
                        transform="translate(-330 -330.42)"
                    />
                    <path
                        d="M670,502c-2.67,3.91-4.83,8.33-8.11,11.66-20.45,20.77-41.16,41.29-61.75,61.93-4.28,4.3-9.37,5.79-15.07,4.18-5.37-1.52-8.71-5.43-9.86-10.91s.8-9.77,4.59-13.54c12-12,24-24.05,36.08-36a35.25,35.25,0,0,1,4.45-3.25l-.81-1.8H614q-95,0-189.92,0a42.3,42.3,0,0,1-7.48-.29c-6.68-1.21-11.46-7.64-11.13-14.58a13.93,13.93,0,0,1,12.89-13.5c2.16-.16,4.33-.1,6.49-.1h195.6c-1.83-2-2.92-3.32-4.11-4.51q-17.83-17.87-35.68-35.7c-7-7-7.57-15.51-1.55-21.63s14.82-5.64,21.69,1.21q31.86,31.75,63.54,63.69c2.46,2.49,3.79,6.1,5.64,9.19Z"
                        transform="translate(-330 -330.42)"
                    />
                </svg>
            </div>
            {logConfirm && (
                <PopupConfirmer
                    close={() => setLogConfirm(false)}
                    confirm={() => {
                        localStorage.setItem("old-host", localStorage.getItem("host"));
                        localStorage.removeItem("host");
                        localStorage.removeItem("logged-user");
                        window.location.reload();
                    }}
                />
            )}
        </>
    );
};

export default LogoutComponent;
