import React from "react";

const Grid = ({ provider, ItemBuilder }) => {
    [provider.items, provider.setItems] = React.useState([]);
    provider.setItem = React.useMemo(() => {
        return (item) =>
            provider.setItems((items) =>
                items.map((i) => {
                    if (i.id === item.id) {
                        console.log("item", item);
                        i = item;
                    }
                    return i;
                })
            );
    }, []);

    return (
        <div id="items-container">
            {provider.items.map((item, i) => (
                <ItemBuilder key={i} item={item} provider={provider} />
            ))}
        </div>
    );
};

export default Grid;
