import React from "react";
import String from "./String";
import Boolean from "./Boolean";
import Selector from "./Selector";
import Date from "./Date";
import Button from "./Button";
import Options from "./Options";
import Group from "./Group";
import { Fragment } from "../NodeBuilder/NodeBuilder";
import ScannerIcon from "./ScannerIcon";

const FormKit = {
    text: String,
    boolean: Boolean,
    selector: Selector,
    date: Date,
    button: Button,
    options: Options,
    group: Group,
    scanner: ScannerIcon,
};

const GetQueryChild = (child) => {
    const Body = FormKit[child.type];
    return Body ? <Body key={child.key} prop={child} /> : null;
};

export default GetQueryChild;
