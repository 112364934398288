const types = [
    { title: "none", id: -1 },
    { title: "test", id: 1 },
    { title: "all", id: 2 },
];

const options = [
    { title: "none", id: -1 },
    { title: "test", id: 1 },
    { title: "all", id: 2 },
];

const section = [
    {
        className: "scroller form-section px-4",
        children: [
            // { key: "currencyId", type: "None", title: "العملة", value: localStorage.getItem("TheCurrency") || 1 },
            { key: "limit", type: "None", title: "", value: 10 },
            // { key: "touchonly", type: "None", title: "", value: true },
            {
                key: "scanner-query",
                type: "scanner",
                title: "بحث عن...",
                value: "",
                showInClearBar: false,
            },
            {
                key: "query",
                type: "text",
                title: "بحث عن...",
                value: "",
                containerClass: "flex-grow col relative",
                showInClearBar: false,
            },

            JSON.parse(
                localStorage.getItem("inventory-list") ||
                    JSON.stringify({ key: "storeId", type: "Options", value: -1, options: [{ title: "كل المتاجر", id: -1 }] })
            ),
            JSON.parse(
                localStorage.getItem("groups-list") ||
                    JSON.stringify({ key: "groupId", type: "ButtonsOptions", value: -1, options: [{ title: "الكل", id: -1 }] })
            ),
        ],
    },
];
// const sectionTwo = {
//     children: [
//         { type: "selector", title: "type", key: "type", value: -1, options: types, storageKey: "type", showInClearBar: true },
//         { type: "options", title: "options", key: "options", value: -1, options: options, storageKey: "options", showInClearBar: true },
//     ],
// };
const TestData = { formSections: section };
export default TestData;
